import dashboard from './dashboard';
// import pages from './pages';
// import utilities from './utilities';
// import other from './other';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard]
    // cancelled pages,other,utilities
};

export default menuItems;
